import React from "react";
import { Link } from "react-router-dom"

import { ROUTES } from "../../helpers/menus/routeConstants";

const GettingStarted = () => {
  return(
  <>
    <h1>Getting Started</h1>
    <p>Welcome to the Digital Gateway API ecosystem, designed to streamline the integration of public health data. Whether you're a developer, product owner, or part of a healthcare team.</p>
    <p>This guide will walk you through the essential steps to harness the power of our APIs for public health data transportation, validation, observation, and access.</p>
    <p>To learn how to Develop with our APIs:</p>
    <Link className="usa-button usa-button--outline" to={ROUTES["Develop with our APIs"]} >Click Here</Link>
    <p>To become an API Producer with Digital Gateway:</p>
    <Link className="usa-button usa-button--outline" to={ROUTES["Become an API Producer"]} >Click Here</Link>
  </>
  )
}

export default GettingStarted;