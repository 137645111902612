import React from "react";

const DevelopWithAPIs = () => {
  return(
  <>
    <h1>Develop with our APIs</h1>

    <p>Welcome to the Digital Gateway API ecosystem, designed to streamline the integration of public health data. Whether you're a developer, product owner, or part of a healthcare team, this guide will walk you through the essential steps to harness the power of our APIs for public health data transportation, validation, observation, and access.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>1. Learn About our Platform</h2>

    <p>Our APIs serve specific functions for specific audiences. Additionally, our platform require users to adhere to our standards and policies. Before diving into integration, it’s important to do the following:</p>

    <ul>
      <li>Familiarize yourself with the Digital Gateway API offerings</li>
      <li>Find detailed information on available APIs in our API Catalog</li>
      <li>Understand our standards and policies</li>
      <li>Understand our requirements regarding authentication and authorization</li>
    </ul>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>2. Confirm Your Use Case</h2>

    <p>Ensure your project aligns with the intended scope of our APIs, particularly with regard to their focus on supporting public health initiatives within the United States. Review your use case against the API specifications in our catalog and provide necessary details about your product and its functionalities. This step is crucial to validate your eligibility for API access.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>3. Design and Build Your Software</h2>

    <p>With a solid understanding of our APIs, begin the design and development of your software. Leverage our API documentation as a comprehensive reference throughout the process. Should you encounter questions or challenges, don't hesitate to seek help and support from our dedicated team.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>4. Onboard to our APIs</h2>

    <p>Many of our APIs require that you participate in an onboarding process in order to begin to use them in specific environments. These processes are details in the Getting Started sections for each individual API. Part of this process may include ensuring compliance with regulations related to security, handling of Personally Identifiable Information (PII), and information governance. Plan accordingly, as the onboarding process may take some time.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>5. Test Your Software</h2>

    <p>Utilize our dedicated testing environments to ensure the seamless integration of your software with our APIs. We provide different testing environments tailored to specific APIs. Refer to our testing guide for detailed instructions on validating your software against the API functionalities.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <h2>6. Go Live!</h2>

    <p>Congratulations! Once your software successfully completes the onboarding process, it's time to go live. Launch your application confidently, knowing that it meets the required standards for secure and efficient data integration.</p>

    <div className="margin-y-5 border-bottom border-base-light" aria-hidden></div>

    <p>By following these steps, you'll be able to integrate your software with the Digital Gateway APIs, contributing to the advancement of public health data accessibility and management. For ongoing support and assistance, our team is here to help—happy integrating!</p>
  </>
  )
}

export default DevelopWithAPIs;