import React from "react";

const Card = ({ title, className, children }) => {
  return ( 
    <div className={className ? className : ''}>
      <h3>{title}</h3>
      {children}
    </div>
  )
}

export default Card