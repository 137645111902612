export const menuItems = (slug) => {
  return [
    {
      id: 1,
      name: "API Overview",
      url: `/docs/${slug}/api-overview`
    },
    {
      id: 2,
      name: "Documentation",
      url: `/docs/${slug}/documentation`
    },
    {
      id: 3,
      name: "Getting Started",
      url: `/docs/${slug}/get-started`
    },
    {
      id: 4,
      name: "Change History",
      url: `/docs/${slug}/change-history`
    },
    {
      id: 5,
      name: "Help & Support",
      url: `/docs/${slug}/help-and-support`
    }
  ];
}
