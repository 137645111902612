import React from "react";
import { Link } from "react-router-dom";

import "./Homepage.css"

import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import HomepageSection from "../../components/HomepageSection/HomepageSection";
import Card from "../../components/Card/Card";
import { ROUTES } from "../../helpers/menus/routeConstants";

const Homepage = () => {
  return(
    <>
      <PrimaryNav />
      <HomepageSection>
        <h1 className="font-family-poppy text-dark-blue text-normal">Welcome to Digital Gateway</h1>
        <h2 className="font-family-poppy text-dark-blue text-normal">Visit our Library to Access CDC APIs  </h2>
        <div className="homepage-hero-content grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <p>The CDC Digital Gateway is a central hub that provides API developers with the necessary tools to transfer, validate, and monitor important public health information. Visit our API Library to explore available APIs, choose the ones you need, and start exchanging data with the CDC today.</p>

            <Link className="usa-button" to={ROUTES["API Library"]} >Browse APIs</Link>
          </div>
          <div className="tablet:grid-col-6 text-center">
            <img className="maxh-mobile" src="/homepage-image.jpg" alt="Woman wearing a surgical mask, protective eyewear, and green latex gloves looking at a machine readout" />
          </div>
        </div>
      </HomepageSection>

      <HomepageSection
        className='bg-light-blue about-us-part'
        title='Digital Gateway Spotlight: DEX Upload API'
      >
      <div className='grid-row grid-gap'>
        <div className='grid-col-12 tablet:grid-col-4 desktop:grid-col-4'>
          <Card
            className='margin-right-3'
            title='Find an API'
          >
            <p>Use this specially designed tool to launch reliable uploads, access real-time information, support scalable operations, and more. </p>
            <Link className="usa-button usa-button--outline" to="/apis" >Learn More</Link>
          </Card>
        </div>
        <div className='grid-col-12 tablet:grid-col-4 desktop:grid-col-4'>
          <Card
            className='margin-right-3 desktop:margin-left-3'
            title='Request access'
          >
            <p>Access DEX Upload API with your Secure Access Information Services (SAMS) account to initiate our onboarding process.</p>
            <Link className="usa-button usa-button--outline" to={ROUTES["Submit a Support Ticket"]} >Get Started</Link>
          </Card>
        </div>
        <div className='grid-col-12 tablet:grid-col-4 desktop:grid-col-4'>
          <Card
            className='desktop:margin-left-3'
            title='Try it out'
          >
            <p>Once approved, start using DEX Upload API to share essential data with CDC.</p>
            <p>
              Need help getting started? Check out <Link to={ROUTES["Help & Support"]}>Help & Support</Link> for the answers you need to get the ball rolling.
            </p>
          </Card>
        </div>
      </div>
      </HomepageSection>

      <HomepageSection
        title='Get To Know CDC Digital Gateway'
        infoParagraph='Our mission is to bring all CDC APIs together in one place, making them standardized, secure, and easy for developers to use. By working together, we can use public health data to make quicker decisions and positively impact our communities. To learn more about our platform, click the button below.'
      >
        <Link className="usa-button usa-button--outline" to={ROUTES["About Us"]} >Get Started</Link>
      </HomepageSection>
    </>
  )
}

export default Homepage
