export const faqContent = {
  "About Us": [
    {
      question: "What is CDC Digital Gateway?",
      answer: 
      [
        <p key="1">CDC’s Digital Gateway is a digital inventory of CDC application programming interfaces (APIs) that enables both external partners and internal applications to seamlessly integrate their data with CDC’s core systems.</p>
      ]
    },
    {
      question: "What does Digital Gateway do?",
      answer: 
      [
        <p key="2">The Digital Gateway catalogs CDC APIs and services under one roof to ensure they are standardized, secure, accessible, and socialized for public use.</p>
      ]
    },
    {
      question: "What is the purpose of the Digital Gateway?",
      answer: 
      [
        <p key="3">Our mission is to accelerate innovation in public health via data-driven decision-making by providing a centralized hub, supported by real-time public health data, for those working with CDC APIs.</p>
      ]
    },
    {
      question: "What is the Enterprise Data Exchange (DEX) initiative?",
      answer: 
      [
        <p key="4">DEX is a cloud-based, centralized data service created by CDC. It is designed to securely ingest, validate, and transport large amounts of public health data to CDC programs, acting as CDC’s front door in the data intake process.<br /><br />
        DEX simplifies data exchange by standardizing points of entry via scalable, secure, and automated options for internal CDC partners and programs, as well as those from data senders like state and local governments, health systems, laboratories, and private entities.</p>
      ]
    },
  ],
  "Our APIs (Individual APIs and Products)": [
    {
      question: "Where can I access individual APIs?",
      answer: 
      [
        <p key="5">You can access individual APIs hosted on Digital Gateway by visiting our API library. Browse the API library below to discover the APIs you need to transport, validate, and observe CDC public health data. <br /><br />
        As the Digital Gateway grows, our API library will grow too.</p>
      ]
    },
    {
      question: "What is the Digital Gateway API Library?",
      answer: 
      [
        <p key="6">The Digital Gateway API Library is a one-stop shop for users who want to access individual APIs. Visit this repository to view and sort through our available APIs to find exactly what you need.</p>
      ]
    },
    {
      question: "What are the products listed on the Digital Gateway?",
      answer: 
      [
        <p key="7">You can access individual APIs hosted on the Digital Gateway by visiting our API library. Browse the API library below to discover the APIs you need to transport, validate, and observe CDC public health data.</p>
      ]
    },
    {
      question: "What is UploadAPI?",
      answer: 
      [
        <p key="8">Upload API is the first product featured on the Digital Gateway. It was developed to help data senders quickly share critical public health data with internal CDC programs. It also provides a modern and secure platform for data senders to upload their data directly to CDC Programs. </p>
      ]
    },
    {
      question: "Who can use UploadAPI?",
      answer: 
      [
        <p key="9">Internal CDC programs and external CDC data senders are eligible to use UploadAPI at this time. External CDC data senders include state, local, and tribal public health departments; hospitals or health systems; laboratories; and Trusted Intermediary Senders.</p>
      ]
    },
  ]
}