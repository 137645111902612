import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
  faXTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faPinterestP,
  faSnapchatGhost,
 } from '@fortawesome/free-brands-svg-icons';

 import "./Footer.css"

const cdcFooterLinks = [
  ["U.S. Department of Health & Human Services", "https://www.hhs.gov/"],
  ["Accessibility", "https://www.cdc.gov/other/accessibility.html"],
  ["External Links", "https://www.cdc.gov/Other/disclaimer.html#exit-notification"],
  ["Privacy", "https://www.cdc.gov/other/privacy.html"],
  ["Policies", "https://www.cdc.gov/Other/policies.html"],
  ["No Fear Act", "https://www.cdc.gov/oeeowe/nofearact/"],
  ["FOIA", "https://www.cdc.gov/od/foia/"],
  ["Nondiscrimination", "https://www.cdc.gov/other/nondiscrimination.html"],
  ["OIG", "https://oig.hhs.gov/"],
  ["Vulnerability Disclosure", "https://www.hhs.gov/vulnerability-disclosure-policy/index.html"]
]

const Footer = (props) => {
  const [socialLinks, setSocialLinks] = useState([])

  useEffect(() => {
    let socialLinks = [
      [faFacebookF, "https://www.facebook.com/CDC"],
      [faXTwitter, "https://twitter.com/CDCgov"],
      [faInstagram, "https://www.instagram.com/CDCgov"],
      [faLinkedinIn, "https://www.linkedin.com/company/centers-for-disease-control-and-prevention"],
      [faYoutube, "https://www.youtube.com/cdc"],
      [faPinterestP, "https://www.pinterest.com/cdcgov"],
      [faSnapchatGhost, "https://www.snapchat.com/add/cdcgov"]
    ]

    setSocialLinks(socialLinks)
  }, [])

  return ( 
    <footer className={`usa-footer text-base-lightest ${props.className ? props.className : ''}`}>
      <nav aria-label="Footer navigation" >
        <section className="grid-row grid-gap bg-gray-70 padding-y-2 desktop:padding-x-10">
          <div className="grid-col-5 margin-y-auto">
            <ul className="usa-list usa-list--unstyled display-flex flex-row flex-justify">
              <li>
                <a className="text-base-lightest text-no-underline" href="https://www.cdc.gov/about/">About CDC</a>
              </li>
              <li>
                <a className="text-base-lightest text-no-underline" href="https://www.cdc.gov/cdc-info/index.html">Contact Us</a>
              </li>
              800-232-4636
            </ul>
          </div> 
          <div className="grid-col-7 margin-y-auto">
            <ul className="usa-list usa-list--unstyled display-flex flex-row flex-justify-end">
              {socialLinks.map((social, index) => {
                return (
                  <li key={index} className="socials-circle margin-x-1 bg-base">
                    <a className="text-base-lightest" href={social[1]}>
                      <FontAwesomeIcon icon={social[0]} />
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
        <section className="padding-2 margin-x-auto desktop:padding-x-10">
    <ul className="usa-list usa-list--unstyled footer-links">
    {cdcFooterLinks.map((link, index) => {
      return (
        <li key={index}>
          <Link className="text-base-darkest text-no-underline" to={link[1]}>{link[0]} </Link>
        </li>
      );
    })}
    </ul>
</section>
      </nav>
    </footer>
  )
}

export default Footer