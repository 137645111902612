import React from 'react';
import { Link } from "react-router-dom";

import { ROUTES } from "../../helpers/menus/routeConstants";

const TrainingHub = () => {
  const boxClasses = 
    'text-center grid-col-12 tablet:grid-col-6 border-right border-base-lighter padding-4'
  return (
    <>
      <h1>Training Hub</h1>
      <p>Welcome to Digital Gateway’s Training Hub — your one-stop shop for learning more about our platform and APIs.</p> 
      <p>Access a wide range of educational aids like tutorials, onboarding guides, user manuals, and more.</p> 
      <p>Together we can exchange critical public health information to generate impact across the country.</p>
      <p>Check out the resources below to learn how you can connect with our platform and APIs today.</p>

      <div className='grid-row'>
        <div className={boxClasses}>
          <h2>API Tutorials</h2>
          <p className='text-left minh-10'>As CDC Digital Gateway expands, our API catalogue will expand, too. Have a question about a specific API? Select the button below to quickly find the answers you need.</p>
          <Link className="usa-button usa-button--outline" to={ROUTES["API Specific Tutorials"]} data-testid='tutorials-link'>Learn More</Link>
        </div>

        <div className={boxClasses}>
          <h2>User Onboarding Guides</h2>
          <p className='text-left minh-10'>Want to start using our APIs? Get started now by browsing our user onboarding guides.</p>
          <Link className="usa-button usa-button--outline" to={ROUTES["New User Onboarding"]} data-testid='onboarding-link'>Learn More</Link>
        </div>
      </div>
    </>
  ); 
};

export default TrainingHub;