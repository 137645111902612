import React from "react";
import { Link } from "react-router-dom"

import { ROUTES } from "../../helpers/menus/routeConstants"

const HelpAndSupport = () => {
  return(
  <>
    <h1 className="page-title heading-1">Help & Support</h1>

    <p>The Digital Gateway is designed to empower developers in leveraging CDC data and services to address critical public health challenges. Whether you want to utilize our in-house APIs or contribute an API of your own, we provide a number of accessible resources to help support you in your experience.</p>

    <p>Can’t find what you’re looking for? No problem — you can reach our support team via <Link to={ROUTES["Contact Us"]}>Contact Us</Link> to get the answers you need in no time at all.</p>

    <h2 className="page-title heading-2">Help & Support Resources</h2>

    <p><Link to={ROUTES["Getting Started"]}>Getting Started</Link>: Learn everything you need to begin using our APIs.</p>

    <p><Link to={ROUTES["Training Hub"]}>Training Modules</Link>: Check out these instructions to master our platform, our products, and so much more.</p>

    <p><Link to={ROUTES["FAQs"]}>FAQs</Link>: Find answers to commonly asked questions about the Digital Gateway and everything it contains.</p>

    <p><Link to={ROUTES["Glossary"]}>Glossary</Link>: Discover key terminology to familiarize yourself with important concepts about the Digital Gateway.</p>

    <p><Link to={ROUTES["Standards & Policies"]}>Standards & Policies</Link>: Review the codes, policies, and assurance processes that define the ways we operate across the Digital Gateway.</p>

    <p><Link to={ROUTES["Contact Us"]}>Contact Us</Link>: Reach out to discuss any outstanding questions you might have about the Digital Gateway.</p>  
  </>
  )
}

export default HelpAndSupport;