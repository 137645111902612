import React from "react";
import { Link } from "react-router-dom"

import { ROUTES } from "../../helpers/menus/routeConstants";

const ContactUs = () => {
  return(
  <>
    <h1>Contact Us</h1>
    <p>Whether you are reporting an issue or looking to contribute to Digital Gateway, this page will allow you to start the process.</p>

    <div className="margin-y-6"></div>

    <h2>Become an API Producer</h2>
    <p>Would you like to play a vital role in protecting public health and transforming the way we access critical health information? By providing APIs for the Centers for Disease Control and Prevention (CDC), you can help empower individuals, healthcare providers, and researchers to make informed decisions and combat health threats.</p>
    <Link className="usa-button" to={ROUTES["Become an API Producer"]} >Become an API Producer</Link>

    <div className="margin-y-6"></div>

    <h2>Submit a Support Ticket</h2>
    <p>We are here to help support you on your public health API journey! Feel free to submit a ticket to our support staff to let us know about an issue you have encountered, or to give us feedback on how we are doing, and what we could do better. Provide as much detail as you can to make sure your ticket gets to the right people.</p>
    <Link className="usa-button" to={ROUTES["Submit a Support Ticket"]} >Submit a support ticket</Link>
  </>
  )
}

export default ContactUs;
