import React from "react";
const SupportTicketConfirmation = ({ ticketNumber }) => {
  return (
    <>
      <h1>Your ticket has been submitted</h1>
      <br />
      <h1>{ticketNumber}</h1>
      <p>Thank You, your support ticket has been sent.</p>
      <a href="/" className="usa-button usa-button--outline">
        Go Home
      </a>
    </>
  );
};

export default SupportTicketConfirmation;
