import React, { useState } from "react";
import { faqContent } from "./questions";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const renderFaq = (faq, i) => {
    const isOpen = openIndex === i;
    const content = (
      <div className="faq-box" key={i}>
  <h3 className="faq-question">
    <button onClick={() => handleToggle(i)} className="faq-toggle-button">
      <span>{isOpen ? '-' : '+'}</span>
    </button>
    {faq.question}
    </h3>
        {isOpen && (
          <div className="faq-answer">
            {faq.answer.map((ans) => {
              if (Array.isArray(ans)) {
                return (
                  <ul key={ans.id}>
                    {ans.map((listItem) => (
                      <li key={listItem.id}>{listItem}</li>
                    ))}
                  </ul>
                );
              }
              return <p key={ans.id}>{ans}</p>;
            })}
          </div>
        )}
      </div>
    );

    return content;
  };

  return (
    <>
      <h1 className="heading-1">Frequently Asked Questions</h1>
      <p>Check out our go-to resource for answers to common questions.<br />
      Browse to find helpful information, troubleshoot issues, and gain a deeper understanding of our products and services.</p>
      <div className="full-width-wrapper-hight"></div>
      <div className="full-width-wrapper">
        <div className="full-width-background content-aligned-right">
          <h2 className="heading-2 padding-y-2 margin-y-5 border-base-light page-title heading-2">About Us</h2>
          {faqContent["About Us"].map((faq, i) => renderFaq(faq, i))}
          
          <h2 className="heading-2 padding-y-2 margin-y-5 border-base-light page-title heading-2">Our APIs (Individual APIs and Products)</h2>
          {faqContent["Our APIs (Individual APIs and Products)"].map((faq, i) => renderFaq(faq, i + faqContent["About Us"].length))}
        </div>
      </div>
    </>
  );
};

export default Faq;
