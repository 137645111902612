import React from "react";
const SupportTicketHome = () => {
  return (
    <>
      <div className="page-title heading-1">Submit a Support Ticket</div>

      <p>
        Whether you need help or would like to provide feedback of your own,
        fill out and submit a support ticket by selecting the button below. A
        member of our Support Team will review its contents and get back to you
        shortly. Be sure to include as much information as possible to get a
        faster, more detailed response.{" "}
      </p>
      <a href="/support-ticket/submit-support-ticket" className="usa-button">
        Get Started
      </a>
    </>
  );
};

export default SupportTicketHome;
