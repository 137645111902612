import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({crumbs}) => {
  return (
    <div className="tablet:padding-x-5 padding-y-3 margin-x-5">
      <nav className="usa-breadcrumb--wrap" aria-label="Breadcrumbs">
        <ol className="usa-breadcrumb__list">
          {crumbs.map((crumb, index) => {
            return <li key={index} className="usa-breadcrumb__list-item">
              {crumb.link ? 
                <Link to={crumb.link} className="usa-breadcrumb__link"><span>{crumb.name}</span></Link>
                :
                <span>{crumb.name}</span>
            }
            </li>
          })}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs;
