import React from 'react'

const HomepageSection = ({title, infoParagraph, children, className}) => {
  return (
    <section className={`padding-5 tablet:padding-10 ${className ? className : ''}`} data-testid='section-stub'>
      <h2 className='text-dark-blue text-normal maxw-tablet'>{title}</h2>
      <p data-testid='section-info'>{infoParagraph}</p>
      {children}
    </section>
  )
}

export default HomepageSection