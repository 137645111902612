import axios from "axios";

export async function contactUsRequest(formData) {
  try {
    const data = `{ "sysparm_quantity": "1", "variables": ${JSON.stringify(
      formData
    )}}`;
    const apimURL = `https://${process.env.REACT_APP_GATEWAY_URL}/contact-us/order_now`;
    const response = await axios({
      url: apimURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error making the ServiceNow request:", error);
    throw error;
  }
}

export async function serviceNowRequest(formData) {
  try {
    const data = `{ "sysparm_quantity": "1", "variables": ${JSON.stringify(
      formData
    )}}`;
    const apimURL = `https://${process.env.REACT_APP_GATEWAY_URL}/service_now/order_now`;
    const response = await axios({
      url: apimURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error making the ServiceNow request:", error);
    throw error;
  }
}
