import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import AboutUs from './pages/AboutUs/AboutUs';
import './components/components.css'
import APIDocsTemplate from './pages/APIDocsTemplate/APIDocsTemplate';
import Banner from './components/Banner/Banner'
import BecomeAPIProducer from './pages/BecomeAPIProducer/BecomeAPIProducer';
import ContactUs from './pages/ContactUs/ContactUs';
import DevelopWithAPIs from './pages/DevelopWithAPIs/DevelopWithAPIs';
import Faq from './pages/FAQ/Faq';
import Footer from "./components/Footer/Footer";
import GettingStarted from './pages/GettingStarted/GettingStarted';
import HelpAndSupport from './pages/HelpAndSupport/HelpAndSupport';
import HelpAndSupportTemplate from './pages/HelpAndSupport/HelpAndSupportTemplate';
import Homepage from './pages/Homepage/Homepage';
import StandardsAndPolicies from './pages/StandardsAndPolicies/StandardsAndPolicies';
import SupportTicket from './pages/SupportTicket/SupportTicket';
import TrainingHub from './pages/TrainingHub/TrainingHub';
import SupportTicketHome from './pages/SupportTicket/SupportTicketHome';
import APILibrary from './pages/APILibrary/APILibrary';


function Page404() {
  return(<h1 className='text-center margin-y-10'>404: Page Not Found</h1>)
}

function App() {
  return (
    <Router>
        <Banner />
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/docs/:slug/:section/*" element={<APIDocsTemplate />} />
            <Route path="/apis" element={<APILibrary />} />
            <Route path="/help" element={
              <HelpAndSupportTemplate>
                <HelpAndSupport />
              </HelpAndSupportTemplate>
            } />
            <Route path="/getting-started" element={
              <HelpAndSupportTemplate crumb={{name: "Getting Started"}}>
                <GettingStarted />
              </HelpAndSupportTemplate>
            } />
            <Route path="/getting-started/dev-with-our-apis" element={
              <HelpAndSupportTemplate crumb={[
                {name: "Getting Started", link: "/getting-started"},
                {name: "Develop with our APIs"}
              ]}>
                <DevelopWithAPIs />
              </HelpAndSupportTemplate>
            } />
            <Route path="/getting-started/become-contributor" element={
              <HelpAndSupportTemplate crumb={[
                {name: "Getting Started", link: "/getting-started"},
                {name: "Become an API Producer"}
              ]}>
                <BecomeAPIProducer />
              </HelpAndSupportTemplate>
            } />
            <Route path="/support-ticket" element={
              <HelpAndSupportTemplate crumb={[
                {name: "Submit a Support Ticket"}
              ]}>
                <SupportTicketHome />
              </HelpAndSupportTemplate>
           }/>
            <Route path="/support-ticket/submit-support-ticket" element={
              <SupportTicket></SupportTicket>
           }/>
            <Route path="/training-hub" element={
              <HelpAndSupportTemplate 
                crumb={[{name: "Training Hub"}]}
                hideSidebar
              >
                <TrainingHub />
              </HelpAndSupportTemplate>
            } />
            <Route path="/faq" element={
              <HelpAndSupportTemplate crumb={{name: "FAQs"}}>
                <Faq />
              </HelpAndSupportTemplate>
            } />
            <Route path="/standards" element={
              <HelpAndSupportTemplate crumb={{name: "Standards & Policies"}}>
                <StandardsAndPolicies />
              </HelpAndSupportTemplate>
            } />
            <Route path="/contact" element={
              <HelpAndSupportTemplate crumb={{name: "Contact Us"}}>
                <ContactUs />
              </HelpAndSupportTemplate>
            } />
            <Route path="*" element={<Page404 />} />
        </Routes>
      <Footer />
    </Router>
  );
} 

export default App;
