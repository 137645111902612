import React from "react";

const ServiceNowQuestion = ({
  question,
  details,
  requiredQuestion,
  label,
  optionalClass,
}) => {
  const questionClass = `usa-label service-now-question ${optionalClass}`;
  const descriptionClass = `service-now-description ${optionalClass}`;
  return (
    <label htmlFor={label}>
      <div className={questionClass}>
        {question}
        {requiredQuestion ? (
          <span className="service-now-asterisk">*</span>
        ) : (
          <></>
        )}
      </div>
      <div className="break"></div>
      {details !== "" ? (
        <div className={descriptionClass}>{details}</div>
      ) : (
        <></>
      )}
    </label>
  );
};

export default ServiceNowQuestion;
