import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PrimaryNav from '../../components/PrimaryNav/PrimaryNav';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SidebarNav from '../../components/SidebarNav/SidebarNav';

import { menuItems } from '../../helpers/menus/docsSidebarMenuItems'

const TableRenderer = ({ children }) => {
  return <table className='usa-table'>{children}</table>;
}

const MarkdownDocs = () => {
  const { slug, section } = useParams();
  const [content, setContent] = useState('');

  let breadcrumbs = [
    {name: "Home", link: "/"},
    {name: "Products", link: "/products"},
    {name: slug}
  ]

  useEffect(() => {
    import(`../../api-docs/${slug}/${section}.md`)
      .then((file) => {
        fetch(file.default)
          .then((res) => res.text())
          .then((text) => setContent(text))
          .catch((err) => setContent("Error loading documentation"))
      })
      .catch((err) => setContent('File not found'))
  }, [slug, section])

  return(
    <>
      <PrimaryNav />
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="bg-primary-dark padding-10 margin-bottom-5">
        <h1 className="text-base-lightest margin-0">DEX Upload API</h1>
      </div>
      <div className="grid-row grid-gap padding-x-5">
        <SidebarNav menuItems={menuItems(slug)} />
        <div className="tablet:grid-col-8">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              table: TableRenderer,
            }}
          >
              {content}
            </ReactMarkdown>
        </div>
      </div>
    </>
  )
}

export default MarkdownDocs;
