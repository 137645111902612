import React from "react";

const LibraryCard = ({ api }) => {
  return (
  <a href={api.link || '#'} className="library-card-box">
    <div className="tablet:padding-x-2 padding-y-1 margin-y-5 library-card bg-white">
      <p className="title">{api.name}</p>
      <p>{api.description}</p>
      <p>
        <span className="text-bold">Date Available:</span> {api.availability}
      </p>
    </div></a>
  );
};

export default LibraryCard;
