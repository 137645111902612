import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from "@us-gov-cdc/cdc-react-icons"

import './PrimaryNav.css'

import { ROUTES } from '../../helpers/menus/routeConstants';

const PrimaryNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    closeHelpMenu()
    setIsOpen(!isOpen);
  };

  const toggleHelpMenu = () => {
    setIsHelpMenuOpen(!isHelpMenuOpen);
  };

  const closeHelpMenu = () => {
    setIsHelpMenuOpen(false)
  }
  
  const hamburgerMenu = 
    <svg 
      className={`text-base-lightest usa-icon usa-icon--size-4`}
      aria-label="Open the menu"
      xmlns="http://www.w3.org/2000/svg" 
      width="1em" 
      height="1em" 
      viewBox="0 0 24 24"  
      focusable="false"
      role="img">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </svg>


  const closeButton =
    <svg 
      className={`text-base-lightest usa-icon usa-icon--size-4`}
      aria-label="Close the menu"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      focusable="false"
      role="img">
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>

  return (
    <nav className="primary-nav text-base-lightest padding-3 tablet:padding-y-0 tablet:padding-x-10" role="navigation" aria-label="Primary Navigation">

      <div data-testid="mobile-menu" className={`menu-toggle grid-row flex-justify-end tablet:display-none`} onClick={toggleMobileMenu}>
        <button aria-expanded={isOpen} aria-controls="menu-items" className="usa-button--unstyled" >
          {isOpen? closeButton : hamburgerMenu}
        </button>
      </div>

      <div className='grid-row grid-gap'>
        <div className='grid-col-6 tablet:display-flex flex-align-center'>
          <a href='/'><img src="/cdc-logo.svg" width="92px" alt="CDC logo" /></a>
          <a href='/' className='text-base-lightest text-no-underline'>
            <p className='tablet:margin-left-2 font-sans-lg tablet:font-sans-xl font-family-poppins'>
              DIGITAL GATEWAY
            </p>
          </a>
        </div>
        <div id="menu-items" data-testid="menu-items" className={`${isOpen ? '' : 'display-none'} grid-col-6 tablet:display-flex flex-align-end`}>
          <ul className="usa-accordion display-flex flex-justify-end flex-column tablet:flex-row">
            <li key="apiLibrary" onMouseEnter={closeHelpMenu}>
              <Link 
                to={ROUTES["API Library"]} 
                className='padding-105 height-full nav-bg-dark-blue-hover display-inline-block text-base-lightest text-no-underline'>
                  API Library
              </Link>
            </li>

            <li key="about" onMouseEnter={closeHelpMenu}>
              <Link 
                to={ROUTES["About Us"]} 
                className='padding-105 height-full nav-bg-dark-blue-hover display-inline-block text-base-lightest text-no-underline'>
                  About Us
              </Link>
            </li>

            <li key="status" onMouseEnter={closeHelpMenu}>
              <Link 
                to={ROUTES["API Status"]} 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="API Status page opens in a new tab"
                className='padding-105 height-full nav-bg-dark-blue-hover display-inline-block text-base-lightest text-no-underline'>
                  API Status
              </Link>
            </li>

            <li key="help" className='position-relative'>
              <button 
                className={`${isHelpMenuOpen ? 'nav-bg-dark-blue' : ''} usa-button usa-button--unstyled padding-105 height-full nav-bg-dark-blue-hover display-flex flex-align-center text-base-lightest text-no-underline`}
                onClick={toggleHelpMenu}
                >
                  <span>Help & Support</span>
                  {isHelpMenuOpen ? 
                    <Icons.ChevronUp/>
                    :
                    <Icons.ChevronDown/>
                  }
              </button>
              <ul className={`${isHelpMenuOpen? '' : 'display-none'} usa-accordion usa-list--unstyled padding-105 nav-bg-dark-blue position-absolute z-top`}>
                <li className='font-sans-2xs' onClick={closeHelpMenu}>
                  <Link 
                    to={ROUTES["Become an API Producer"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    Become an API Producer
                  </Link>
                </li>
                <li className='font-sans-2xs' onClick={closeHelpMenu}>
                  <Link 
                    to={ROUTES["Submit a Support Ticket"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    Submit a Support Ticket
                  </Link>
                </li>
                <li className='font-sans-2xs' onClick={closeHelpMenu}>
                  <Link 
                    to={ROUTES["FAQs"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    FAQs
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PrimaryNav;