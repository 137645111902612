import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SidebarNav from "../../components/SidebarNav/SidebarNav";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import menuItems from "../../helpers/menus/sidebarMenu.json"


const HelpAndSupportTemplate = ({ children, crumb, hideSidebar }) => {
    let breadcrumbs = [
      {name: "Home", link: "/"},
      {name: "Help & Support", link: "/help"}
    ]
  
    breadcrumbs = crumb ? breadcrumbs.concat(crumb) : breadcrumbs
  
    return (
      <div>
        <PrimaryNav />
        <Breadcrumbs crumbs={breadcrumbs} />
        <div className="grid-row grid-gap padding-x-10">
          {!hideSidebar && <SidebarNav menuItems={menuItems} />}
          <div className={hideSidebar ? "" : "tablet:grid-col-8"}>
            { children }
          </div>
        </div>
      </div>
    );
  }
  export default HelpAndSupportTemplate
