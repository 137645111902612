import React from 'react'

const BecomeAPIProducer = () => {
  return (
    <>
      <h1 className="page-title heading-1">Become an API Producer</h1>
      <p>
        As a CDC API provider, you'll be joining a community of dedicated partners who are harnessing the power of technology to advance public health. Your APIs will serve as gateways to a wealth of CDC data and resources, enabling seamless integration with applications, websites, and research platforms.
      </p>
      <p>
        By contributing your expertise in API development and data management, you'll be directly contributing to the CDC's mission to protect the health of the nation. Your APIs will have the potential to:
      </p>
      <ul className='usa-list'>
        <li>
          Empower individuals to make informed health choices by providing access to up-to-date disease information, prevention strategies, and treatment options.
        </li>
        <li>
          Facilitate real-time disease surveillance and outbreak detection by enabling researchers and public health officials to analyze large datasets and identify trends.
        </li>
        <li>
          Promote the development of innovative health applications and research tools by providing a standardized interface to CDC data and resources.
        </li>
      </ul>
      <p>
        If you're passionate about leveraging technology to improve public health, providing APIs for the CDC is an opportunity to make a meaningful impact on the lives of millions. Your contributions will help shape the future of public health data access and empower communities to make informed decisions that protect their health.
      </p>
      <div>
        {/* service now questionaire link */}
        <a className='usa-button' href='https://testsnowcdc.servicenowservices.com/esc?id=sc_cat_item&sys_id=7a1180f61b1af510d059a86ce54bcbd5&sysparm_category=91c74f6c1bb94990a3fb62cae54bcb0e'>Get Started</a>
      </div>
      <p></p>
    </>
  )
}

export default BecomeAPIProducer;

