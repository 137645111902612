
export const cdcServices = ["Dex"]

export const apis = ["DEX Upload API"]

export const environments = ["Development", "Staging", "Test", "Production"]

export const priority = ["Low", "Medium", "High"]

export const yesNo = ["Yes", "No"]
