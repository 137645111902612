// created useing routeGenerator.js
// using this instead of the dynamic version for preformance resons
export const ROUTES = {
  "About Us": "/about-us",
  "API Library": "/apis",
  "API Status": "https://digitalgateway.statuspage.io/",
  "Help & Support": "/help",
  "Getting Started": "/getting-started",
  "Develop with our APIs": "/getting-started/dev-with-our-apis",
  "Become an API Producer": "/getting-started/become-contributor",
  "Training Hub": "/training-hub",
  "API Specific Tutorials": "/training-hub/api-tutorials",
  "UploadAPI": "/training-hub/api-tutorials/upload-api",
  "New User Onboarding": "/training-hub/user-onboarding",
  "FAQs": "/faq",
  "Glossary": "/glossary",
  "Standards & Policies": "/standards",
  "Contact Us": "/contact",
  "Submit a Support Ticket": "/support-ticket",
  "Request": "/products/access/request",
}
